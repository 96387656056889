import { useLocation } from "@remix-run/react";
import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useState } from "react";

type Context = [URLSearchParams, (newParams: URLSearchParams) => void];
const SearchParamsContext = createContext<Context>([new URLSearchParams(), () => {}]);

// Using context to centralize search params and update them when Remix route changes
export function SearchParamsNoReloadProvider({ children }: { children: ReactNode }) {
  const location = useLocation();
  const [params, setParams] = useState(new URLSearchParams(location.search));

  useEffect(() => {
    setParams(new URLSearchParams(location.search));
  }, [location]);

  return (
    <SearchParamsContext.Provider value={[params, setParams]}>
      {children}
    </SearchParamsContext.Provider>
  );
}

export function useSearchParamsNoReload() {
  const [params, setParams] = useContext(SearchParamsContext);

  function setSearchParams(newParams: URLSearchParams) {
    const currentUrl = new URL(window.location.href);
    currentUrl.search = newParams.toString();
    window.history.replaceState({}, "", currentUrl.toString());
    setParams(newParams);
  }

  return [params, setSearchParams] as const;
}
